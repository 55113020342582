import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
// import { url } from "services/config";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/schoolback.jpg";
import {useState as useHookState} from '@hookstate/core';
import GolobalState from "../../../components/GolobalState";
import { ConfigFile } from "../../../services/ConfigFile";


function Basic() {
  const path = ConfigFile.BaseUrl;
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

 





  const {TotalItem, TotalCost} = useHookState(GolobalState);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });


 
  if(window.location.pathname==="/adminpasslogin")
    {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const email = params.get("email");
      const password = params.get("password");
     // console.log("email",email);
    //  console.log("adminid", password);
    
    const headers = {
      "Content-Type": "application/json",
    };
       const logindata = {
       email: email,
       password: password
     };

     fetch(`${path}/admin/AdminLogin/adminpasslogin`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(logindata),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("ssssssssss",data.Status);
        if (data.Status === "Success") {
     
          localStorage.setItem("token", data.authToken);
          localStorage.setItem("LoginStatus", true);
          localStorage.setItem("userdata", JSON.stringify(data.data[0][0]));

          TotalItem.set(data.authToken);
         navigate("/dashboard");
        } else {
          console.error("Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    }


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const headers = {
      "Content-Type": "application/json",
    };

    fetch(`${path}/admin/AdminLogin`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === "Success") {
          localStorage.setItem("token", data.authToken);
          localStorage.setItem("LoginStatus", true);
          localStorage.setItem("userdata", JSON.stringify(data.data[0][0]));

          TotalItem.set(data.authToken);
         navigate("/dashboard");
        } else {
          console.error("Login failed:", data.error);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                value={formData.email}
                name="email"
                onChange={handleChange}
                label="Email"
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  value={formData.password}
                  name="password"
                  onChange={handleChange}
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
            </MDBox>
      
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
