 //const BASE_URL="http://192.168.1.12/bansal_group/"
 

export const ConfigFile = {
  // BaseUrl: "http://192.168.1.12/bansal_group/api",
  // ImageUrl: "http://192.168.1.12//bansal_group/uploads",
  //  LocalUrl: "http://localhost:3000/",
   branchurl:"https://demoerp.ubkinfotech.com/",

//   ImageUrl: "https://liveerp.ubkinfotech.com/webservice/uploads",
//  LocalUrl: "https://liveadmin.ubkinfotech.com/",

  BaseUrl: "https://demoerp.ubkinfotech.com/webservice/api",
  ImageUrl: "https://demoerp.ubkinfotech.com/webservice/uploads",
 LocalUrl: "https://demoerp.ubkinfotech.com/",
};
